<template>
    <div class="field-order-pack">
        <!-- 标题 -->
        <div class="field-order-pack-title">套餐详情 / Details</div>
        <!-- 内容 -->
        <detail-more
            v-for="(item, index) in 3"
            :key="index">
            <template>
                <div class="field-order-item">
                    <!-- 左侧 -->
                    <div class="field-order-item-left">
                        <!-- 标题 -->
                        <div class="field-order-item-title">套餐A：单人可爱和风和服人像写真</div>
                        <!-- 包含内容 -->
                        <ul class="field-order-item-include">
                            <li>摄影套餐含：</li>
                            <li>1）1人份环境人像-可爱（含摄影师&化妆师各一名）</li>
                            <li>2）服饰店内自选</li>
                            <li>3）抹茶蛋糕一份+抹茶薄荷苏打绿一杯</li>
                        </ul>
                        <!-- 价格 -->
                        <div class="field-order-item-price">
                            <span>￥</span>
                            <span>347</span>
                            <span class="field-order-item-price-discount">
                                8折
                            </span>
                        </div>
                    </div>
                    <!-- 右侧 -->
                    <div class="field-order-item-right">
                        套餐样片
                    </div>
                </div>
            </template>
        </detail-more>
    </div>
</template>

<script>
    export default {
        components: {
            detailMore: () => import("@/components/detailMore"),
        }
    }
</script>

<style scoped>
.field-order-pack{
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
}
.field-order-pack > div + div{
    margin-top: 30px;
}
.field-order-pack-title{
    font-size: 20px;
    color: #303133;
}

/*  */
.field-order-item{
    display: flex;
    justify-content: space-between;
    height: 100%;
}
/* 左侧 */
.field-order-item-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}
.field-order-item-title{
    margin-bottom: 6px;
    font-size: 18px;
}
.field-order-item-include{
    flex: 1;
}
.field-order-item-include > li{
    color: #909399;
}
/* 价格 */
.field-order-item-price > span{
    margin-right: 6px;
    font-size: 16px;
    color: #E6A23C;
}
.field-order-item-price-discount{
    display: inline-block;
    padding: 0 12px;
    border-radius: 30px;
    background-color: #E6A23C;
    color: #fff !important;
}
/* 右侧 */
.field-order-item-right{
    align-self: center;
    width: 50px;
    padding: 0 14px;
    border-left: 1px solid #F2F6FC;
    text-align: center;
    font-size: 18px;
    color: #909399;
}
</style>